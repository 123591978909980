define(['santa-components', 'lodash', 'prop-types', 'componentsCore'], function (santaComponents, _, PropTypes, componentsCore) {
    'use strict';

    /**
     * Note that this is run by using runValidators.apply, in order to use the right context
     * @returns {string}
     */
    function runValidators() {
        const validators = this.props.validators || [];
        const value = this.state.value;

        let errorMessage = '';
        _.forEach(validators, function (validator) {
            if (errorMessage) {
                return false;
            }
            errorMessage = validator(value);
        });
        this.safelySetState({error: errorMessage});
        this.hasError = !!errorMessage;

        return errorMessage;
    }

    function getRelevantErrorMsg(errorMessageClassName, noErrorParams) {
        let res;
        if (this.state.error) {
            res = {className: errorMessageClassName, children: this.state.error};
        } else if (this.props.asyncErrorMessage) {
            res = {className: errorMessageClassName, children: this.props.asyncErrorMessage};
        } else {
            res = noErrorParams;
        }
        return res;
    }

    /**
     * @class components.InputWithValidation
     * @extends {core.skinBasedComp}
     */
    const inputWithValidation = {
        displayName: 'InputWithValidation',
        mixins: [componentsCore.mixins.skinBasedComp],

        propTypes: {
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView.isRequired,
            validators: PropTypes.array,
            asyncErrorMessage: PropTypes.string,
            defaultValue: PropTypes.string,
            styleId: PropTypes.string,
            onChange: PropTypes.func,
            lazyValidation: PropTypes.bool,
            label: PropTypes.string,
            placeholder: PropTypes.string,
            noPlaceHolder: PropTypes.bool,
            type: PropTypes.string,
            trimValue: PropTypes.bool,
            enableLowerLabel: PropTypes.bool
        },

        getInitialState() {
            return {
                value: this.props.defaultValue || '',
                error: false
            };
        },

        onChange(e) {
            e.persist();

            const val = this.props.trimValue ? (e.target.value || '').trim() : e.target.value;
            this.safelySetState({value: val});

            if (this.props.onChange) {
                this.props.onChange(e);
            }
            this.debouncedOnChange(e);
            if (this.props.lazyValidation) {
                this.safelySetState({error: false});
            }
        },
        debouncedOnChange: _.debounce(function () {
            if (!this.props.lazyValidation) {
                this.validate();
            }
        }, 200),

        getValue() {
            return this.state.value;
        },
        getSkinProperties() { // eslint-disable-line complexity
            const errorClass = `${this.props.styleId}_error`;
            let errorMessageClassName = errorClass;
            let inputClassName = this.state.error || this.props.asyncErrorMessage ? errorClass : '';
            const newClass = `${this.props.styleId}_new`;
            errorMessageClassName += ` ${newClass}`;
            inputClassName += ` ${newClass}`;
            const extraInputProps = {
                onFocus: this.onFocus,
                onBlur: this.onBlur
            };
            const noErrorParams = {className: newClass};
            if (this.props.isMobileView) {
                inputClassName += ` ${this.props.styleId}_mobile`;
                errorMessageClassName += ` ${this.props.styleId}_mobile`;
            }
            const inputId = `${this.props.id}input`;
            const labelProps = {htmlFor: inputId, children: this.props.label};
            return {
                label: this.props.label && !this.props.enableLowerLabel ? labelProps : {},
                lowerLabel: this.props.label && this.props.enableLowerLabel ? labelProps : {},
                input: _.merge({
                    id: inputId,
                    className: inputClassName,
                    value: this.state.value,
                    placeholder: this.props.enableLowerLabel || this.state.noPlaceHolder ? '' : this.props.placeholder,
                    'aria-label': this.props.label ? this.props.label : this.props.placeholder,
                    onChange: this.onChange,
                    required: true,
                    type: this.props.type || 'text'
                }, extraInputProps),
                errorMessage: getRelevantErrorMsg.bind(this)(errorMessageClassName, noErrorParams)
            };
        },
        componentDidMount() {
            this._isMounted = true;
        },
        componentWillUnmount() {
            this._isMounted = false;
        },
        safelySetState(newState) {
            if (this._isMounted) {
                this.setState(newState);
            }
        },
        isValid() {
            return !this.hasError;
        },
        validate() {
            return !runValidators.apply(this);
        },
        onFocus() {
            this.safelySetState({noPlaceHolder: true});
        },
        onBlur() {
            this.safelySetState({noPlaceHolder: false});
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.components.viewer.inputs.InputWithValidation', inputWithValidation);

    return inputWithValidation;
});

















define([
    'lodash',
    'santa-components'
], function (
    _,
    santaComponents
) {
    'use strict';

    function getCustomCompCss(getSkin, props) {
        const customStyleId = 'b1';
        const {getStyleData,
            themeData,
            siteZoomRatio,
            invertedZoomRatio,
            orientationZoomFix,
            mobileZoom,
            scriptsLocationMap} = props;
        const mobileData = {siteZoomRatio, invertedZoomRatio, orientationZoomFix, mobileZoom};
        const serviceTopology = {scriptsLocationMap};

        const styleData = getStyleData(customStyleId);
        const css = santaComponents.utils.skinsRenderer.createSkinCss(getSkin(styleData.skin), _.get(styleData, 'style.properties', {}), themeData, customStyleId, mobileData, serviceTopology, getSkin);

        return css && {[customStyleId]: css};
    }

    getCustomCompCss.cssTypes = {
        getStyleData: santaComponents.santaTypesDefinitions.Component.getStyleData,
        themeData: santaComponents.santaTypesDefinitions.Theme.THEME_DATA,
        siteZoomRatio: santaComponents.santaTypesDefinitions.Mobile.siteZoomRatio,
        invertedZoomRatio: santaComponents.santaTypesDefinitions.Mobile.invertedZoomRatio,
        orientationZoomFix: santaComponents.santaTypesDefinitions.Mobile.orientationZoomFix,
        mobileZoom: santaComponents.santaTypesDefinitions.Mobile.mobileZoom,
        scriptsLocationMap: santaComponents.santaTypesDefinitions.ServiceTopology.scriptsLocationMap
    };

    return getCustomCompCss;
});

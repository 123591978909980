define(['dialogs/siteMemberDialogs/memberLoginDialog/memberLoginDialog',
    'dialogs/siteMemberDialogs/requestPasswordResetDialog/requestPasswordResetDialog',
    'dialogs/siteMemberDialogs/resetPasswordDialog/resetPasswordDialog',
    'dialogs/siteMemberDialogs/emailVerificationDialog/emailVerificationDialog',
    'dialogs/siteMemberDialogs/sentConfirmationEmailDialog/sentConfirmationEmailDialog',
    'dialogs/siteMemberDialogs/noPermissionsToPageDialog/noPermissionsToPageDialog',
    'dialogs/siteMemberDialogs/welcomeDialog/welcomeDialog',
    'dialogs/siteMemberDialogs/signUpDialog/signUpDialog'
], function (memberLoginDialog, requestPasswordResetDialog, resetPasswordDialog, emailVerificationDialog, sentConfirmationEmailDialog, noPermissionsToPageDialog, welcomeDialog, signUpDialog) { // eslint-disable-line
    'use strict';

    return {

    };
});

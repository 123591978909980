define([
    'santa-components',
    'componentsCore',
    'dialogs/dialogMixin/dialogMixin',
    'prop-types',
    'dialogs/utils/dialogCustomCss'
], function (
    santaComponents,
    componentsCore,
    dialogMixin,
    PropTypes,
    getCustomCompCss
) {
    'use strict';

    /**
     * @class components.siteMembersDialogs.NoPermissionsToPageDialog
     * @extends {core.skinBasedComp}
     * @extends {ReactCompositeComponent}
     * @property {comp.properties} props
     */
    const noPermissionsToPageDialog = {
        displayName: 'NoPermissionsToPageDialog',
        mixins: [componentsCore.mixins.customSkinBasedComp({customCssFunc: getCustomCompCss}), dialogMixin],

        propTypes: {
            language: PropTypes.string,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen
        },

        getSkinProperties() {
            if (this.shouldDialogBeClosed()) {
                return this.getCloseDialogSkinProperties();
            }

            const lang = this.props.language;
            const titleTxt = this.getText(lang, 'SMNoPermissions');
            const backToSiteButtonTxt = this.getText(lang, 'SMWelcome_Back');
            const switchAccountLinkTxt = this.getText(lang, 'SMSwitchAccount');

            return {
                'blockingLayer': {},
                'title': {
                    children: titleTxt
                },
                'okButton': {
                    children: backToSiteButtonTxt,
                    onClick: () => {
                        this.onClickCloseButton();
                    }
                },
                'switchAccountLink': {
                    children: switchAccountLinkTxt,
                    onClick: this.props.onSwitchAccountClick
                }
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.dialogs.siteMemberDialogs.NoPermissionsToPageDialog',
        noPermissionsToPageDialog);

    return noPermissionsToPageDialog;
});

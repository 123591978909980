define([
    'lodash',
    'dialogs/dialogMixin/dialogMixin',
    'prop-types',
    'componentsCore'
], function (_, dialogMixin, PropTypes, componentsCore) {
    'use strict';

    /**
     * @class components.siteMembersDialogs.EmailVerificationDialog
     * @extends {core.skinBasedComp}
     * @extends {ReactCompositeComponent}
     * @property {comp.properties} props
     */
    const emailVerificationDialog = {
        displayName: 'EmailVerificationDialog',
        mixins: [componentsCore.mixins.skinBasedComp, dialogMixin],

        propTypes: {
            language: PropTypes.string,
            onResendLinkClick: PropTypes.func
        },


        getSkinProperties() {
            if (this.shouldDialogBeClosed()) {
                return this.getCloseDialogSkinProperties();
            }

            const lang = this.props.language;

            const titleTxt = this.getText(lang, 'SMEmailVerification_Title');
            const thanksTxt = `${this.getText(lang, 'SMEmailVerification_Thanks')} `;
            const clickTheLinkTxt = this.getText(lang, 'SMEmailVerification_ClickTheLink');
            const didntGetTxt = this.getText(lang, 'SMEmailVerification_DidntGetEmail').replace('&#39;', "'");
            const checkSpamTxt = `${this.getText(lang, 'SMEmailVerification_CheckSpam')} `;
            const resendLinkTxt = this.getText(lang, 'SMEmailVerification_ResendLink');

            return {
                'blockingLayer': {},
                'title': {
                    children: titleTxt
                },
                'thanksTxt': {
                    children: thanksTxt
                },
                'clickTheLinkTxt': {
                    children: clickTheLinkTxt
                },
                'didntGetTxt': {
                    children: didntGetTxt
                },
                'checkSpamTxt': {
                    children: checkSpamTxt
                },
                'resendLink': {
                    children: resendLinkTxt,
                    onClick: this.props.onResendLinkClick
                },
                'xButton': this.createXButton()
            };
        },

        componentDidMount() {
            this.props.onDialogReady();
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.dialogs.siteMemberDialogs.EmailVerificationDialog',
        emailVerificationDialog);

    return emailVerificationDialog;
});

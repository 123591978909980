define([
    'santa-components',
    'lodash',
    'dialogs/dialogMixin/dialogMixin',
    'dialogs/utils/dialogCustomCss',
    'prop-types',
    'componentsCore'
], function (
    santaComponents,
    _,
    dialogMixin,
    getCustomCompCss,
    PropTypes,
    componentsCore
) {
    'use strict';

    /**
     * @class components.siteMembersDialogs.MemberLoginDialog
     * @extends {core.skinBasedComp}
     * @extends {ReactCompositeComponent}
     * @extends {core.postMessageCompMixin}
     * @property {comp.properties} props
     */
    const memberLoginDialog = {
        displayName: 'MemberLoginDialog',
        mixins: [
            componentsCore.mixins.customSkinBasedComp({customCssFunc: getCustomCompCss}),
            componentsCore.mixins.postMessageCompMixin,
            dialogMixin,
            componentsCore.mixins.cyclicTabbingMixin
        ],

        propTypes: {
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView.isRequired,
            notClosable: PropTypes.bool,
            language: PropTypes.string,
            styleId: santaComponents.santaTypesDefinitions.Component.styleId,
            onSwitchDialogLinkClick: PropTypes.func,
            onForgetYourPasswordClick: PropTypes.func,
            defaultEmail: PropTypes.string,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen
        },

        passwordInputRef: 'passwordInput',
        emailInputRef: 'emailInput',
        socialLoginIframeRef: 'socialLoginIframe',
        socialLoginIframeHorizontalRef: 'socialLoginIframeHorizontal',

        isMobile() {
            return this.state.$view === 'mobile';
        },

        validateBeforeSubmit() {
            this.refs[this.emailInputRef].validate();
            this.refs[this.passwordInputRef].validate();

            return this.refs[this.emailInputRef].isValid() && this.refs[this.passwordInputRef].isValid();
        },

        getDataToSubmit() {
            return {
                email: this.refs[this.emailInputRef].getValue(),
                password: this.refs[this.passwordInputRef].getValue(),
                rememberMe: true// || ReactDOM.findDOMNode(this.refs.rememberMeCheckbox).checked
            };
        },

        getFocusElement() {
            return this.state.socialLoginEnabled ?
                this.refs.switchDialogLink :
                this.refs[this.emailInputRef].refs.input;
        },

        getSkinProperties() {//eslint-disable-line complexity
            if (this.shouldDialogBeClosed()) {
                return this.getCloseDialogSkinProperties();
            }

            const isSocialLogin = this.isSocialLogin();
            const lang = this.props.language;
            const titleText = this.getText(lang, 'dialogMixinTranslation_log_in');
            const submitButtonText = this.getText(lang, 'dialogMixinTranslations_log_in_btn_new');
            const switchDialogLinkText = this.getText(lang, 'SMRegister_sign_up_new');
            const rememberMeText = this.getText(lang, 'SMLogin_Remember_Me');
            const mobileForgotText = 'dialogMixinTranslations_forgot_password_mobile_new';
            const forgotYourPasswordText = this.getText(lang,
                this.props.isMobileView ? mobileForgotText : 'dialogMixinTranslations_forgot_password');
            const switchToSignUpText = this.getText(lang, 'dialogMixinTranslations_switch_to_signup_material');
            const errMsgText = this.getErrorMessage();
            const skin = {
                'preloader': this.getDialogPreloaderSkinProperties(),
                'blockingLayer': {},
                'dialog': this.isMobile() ? {style: {marginTop: '-20px'}} : {},
                'switchDialogLink': {
                    children: switchDialogLinkText,
                    onClick: this.props.onSwitchDialogLinkClick,
                    onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton
                },
                'title': {
                    children: titleText
                },
                'socialSectionDividerText': {
                    children: this.getText(lang, 'dialogMixinTranslations_or_log_in_with')
                },
                'emailSectionDividerText': {
                    children: this.getText(lang, 'dialogMixinTranslations_or_email_new')
                },
                'content': this.createContent(),
                'checkboxWrapper': {
                    onClick: this._onRememberMeWrapperClick,
                    onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton
                },
                'rememberMeCheckboxLabel': {
                    children: rememberMeText
                },
                'forgotYourPasswordLink': {
                    children: forgotYourPasswordText,
                    onClick: this._onForgotYourPasswordClick,
                    onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton
                },
                'switchToSignUpText': {
                    children: switchToSignUpText
                },
                'password': {
                    children: this._createPasswordField({
                        enableLowerLabel: true,
                        placeholder: this.getText(lang, 'PasswordLogin_Password'),
                        asyncErrorMessage: errMsgText
                    })
                },
                'email': {
                    children: this._createEmailField({
                        enableLowerLabel: true,
                        placeholder: this.getText(lang, 'SMForm_Email')
                    })
                },
                'okButton': {
                    children: submitButtonText,
                    onClick: this.submit,
                    onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton
                },
                'forgotYourPasswordLinkMobile': {
                    children: forgotYourPasswordText,
                    onClick: this._onForgotYourPasswordClick
                },
                'contentWrapper': {
                    className: `${this.props.styleId}_${isSocialLogin ? 'horizontal' : 'vertical'} ${
                        this.props.styleId}_${isSocialLogin ? this.getMobileSocialLoginClass() : ''}`
                },
                '': {
                    'aria-labelledby': `${this.props.id}title`,
                    onKeyDown: this.preventTabbingOut,
                    role: 'dialog',
                    style: {
                        //restore pointer events after inlineContent of page component, a parent of popupContainer,
                        //got pointerEvents none to make possible click events on overlay
                        pointerEvents: 'auto'
                    },
                    tabIndex: -1
                }
            };
            _.assign(skin, isSocialLogin ? this.getSocialLoginSkinParts('login', lang, this._getSocialTranslationKeys()) : {});

            if (!this.props.notClosable) {
                const closeableSkinProperties = {
                    'blockingLayer': {},
                    'xButton': this.createXButton()
                };
                _.merge(skin, closeableSkinProperties);
            }

            return skin;
        },

        _onForgotYourPasswordClick() {
            this.props.onForgetYourPasswordClick();
        },

        _onRememberMeWrapperClick(e) {
            if (e.target !== this.refs.rememberMeCheckbox) {
                e.stopPropagation();
                this.refs.rememberMeCheckbox.click();
            }
        },

        _createEmailField(extraProps) {
            const emailProperties = {
                refId: this.emailInputRef,
                inputTitleKey: 'SMForm_Email',
                language: this.props.language,
                defaultValue: this.props.defaultEmail
            };
            return this.createEmailInput(emailProperties, extraProps);
        },

        _createPasswordField(extraProps) {
            const passwordProperties = {
                refId: this.passwordInputRef,
                inputTitleKey: 'SMForm_Password',
                language: this.props.language
            };
            return this.createPasswordInput(passwordProperties, extraProps);
        },

        _getSocialTranslationKeys() {
            return {
                google: 'dialogMixinTranslations_login_google',
                facebook: 'dialogMixinTranslations_login_facebook',
                switchEmail: 'dialogMixinTranslations_login_switch_email'
            };
        },

        componentDidMount() {
            this.setPostMessageHandler('wix-social-login', this.onSocialLoginIframeMessage);
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.dialogs.siteMemberDialogs.MemberLoginDialog', memberLoginDialog);

    return memberLoginDialog;
});

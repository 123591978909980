define([
    'skins',
    'dialogs/skins/skins.json',
    'dialogs/notificationDialog/notificationDialog',
    'dialogs/siteMemberDialogs/siteMemberDialogs',
    'dialogs/enterPasswordDialog/enterPasswordDialog',
    'dialogs/erasableTextInput/erasableTextInput',
    'dialogs/inputWithValidation/inputWithValidation'

], function (skinsPackage, skinsJson) { // eslint-disable-line
    'use strict';

    skinsPackage.skinsMap.addBatch(skinsJson);

    return {

    };
});

define([
    'santa-components',
    'lodash',
    'componentsCore',
    'dialogs/dialogMixin/dialogMixin',
    'dialogs/utils/dialogCustomCss',
    'prop-types'
], function (
    santaComponents,
    _,
    componentsCore,
    dialogMixin,
    getCustomCompCss,
    PropTypes
) {
    'use strict';

    /**
     * @class components.siteMembersDialogs.RequestPasswordResetDialog
     * @extends {core.skinBasedComp}
     * @extends {ReactCompositeComponent}
     * @property {comp.properties} props
     */
    const requestPasswordResetDialog = {
        displayName: 'RequestPasswordResetDialog',
        mixins: [
            componentsCore.mixins.customSkinBasedComp({customCssFunc: getCustomCompCss}),
            dialogMixin,
            componentsCore.mixins.cyclicTabbingMixin
        ],

        emailInputRef: 'emailInput',

        propTypes: {
            notClosable: PropTypes.bool,
            language: PropTypes.string,
            defaultEmail: PropTypes.string,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen
        },

        validateBeforeSubmit() {
            this.refs[this.emailInputRef].validate();
            return this.refs[this.emailInputRef].isValid();
        },

        getDataToSubmit() {
            return {
                email: this.refs[this.emailInputRef].getValue()
            };
        },

        getFocusElement() {
            return this.refs[this.emailInputRef].refs.input;
        },

        getSkinProperties() {
            if (this.shouldDialogBeClosed()) {
                return this.getCloseDialogSkinProperties();
            }

            const lang = this.props.language;
            const titleText = this.getText(lang, 'SMResetPassMail_title');
            const submitButtonText = this.getText(lang, 'dialogMixinTranslations_RESET_PASSWORD_BUTTON');
            const pleaseEnterEmailText = this.getText(lang, 'SMResetPassMail_Enter_Email');
            const errMsgText = this.getErrorMessage();

            const skin = {
                'blockingLayer': {},
                'title': {
                    children: titleText
                },
                'errMsg': {
                    children: errMsgText
                },
                'content': this.createContent(),
                'okButton': {
                    children: submitButtonText,
                    onClick: this.submit,
                    onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton

                },
                'subtitle': {
                    children: pleaseEnterEmailText
                },
                'email': {
                    children: this._createEmailInput({
                        enableLowerLabel: true,
                        inputTitleKey: 'SMForm_Email',
                        placeholder: this.getText(lang, 'SMForm_Email')
                    })
                },
                '': {
                    onKeyDown: this.preventTabbingOut,
                    role: 'dialog',
                    style: {
                        //restore pointer events after inlineContent of page component, a parent of popupContainer,
                        //got pointerEvents none to make possible click events on overlay
                        pointerEvents: 'auto'
                    },
                    tabIndex: -1
                }
            };

            if (!this.props.notClosable) {
                const closeableSkinProperties = {
                    'blockingLayer': {},
                    'xButton': this.createXButton()
                };
                _.merge(skin, closeableSkinProperties);
            }

            return skin;
        },

        _createEmailInput(extraProps) {
            const emailProperties = {
                refId: this.emailInputRef,
                inputTitleKey: extraProps.inputTitleKey || 'SMResetPassMail_Enter_Email',
                language: this.props.language,
                defaultValue: this.props.defaultEmail
            };
            return this.createEmailInput(emailProperties, extraProps);
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.dialogs.siteMemberDialogs.RequestPasswordResetDialog', requestPasswordResetDialog);

    return requestPasswordResetDialog;
});

define([
    'santa-components',
    'lodash',
    'dialogs/dialogMixin/dialogMixin',
    'dialogs/utils/dialogCustomCss',
    'prop-types',
    'componentsCore'
], function (
    santaComponents,
    _,
    dialogMixin,
    getCustomCompCss,
    PropTypes,
    componentsCore
) {
    'use strict';

    /**
     * @class components.siteMembersDialogs.SignUpDialog
     * @extends {core.skinBasedComp}
     * @extends {ReactCompositeComponent}
     * @extends {core.postMessageCompMixin}
     * @property {comp.properties} props
     */
    const signUpDialog = {
        displayName: 'SignUpDialog',
        mixins: [
            componentsCore.mixins.customSkinBasedComp({customCssFunc: getCustomCompCss}),
            componentsCore.mixins.postMessageCompMixin,
            dialogMixin,
            componentsCore.mixins.cyclicTabbingMixin
        ],

        propTypes: {
            language: PropTypes.string,
            styleId: santaComponents.santaTypesDefinitions.Component.styleId,
            onSwitchDialogLinkClick: PropTypes.func,
            hasSocialAppInstalled: PropTypes.bool,
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo.isRequired,
            scrollToAnchor: santaComponents.santaTypesDefinitions.scrollToAnchor.isRequired,
            navigateToPage: santaComponents.santaTypesDefinitions.navigateToPage.isRequired,
            linkRenderInfo: santaComponents.santaTypesDefinitions.Link.renderInfo.isRequired,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            checkCommunityCheckbox: PropTypes.bool
        },

        passwordInputRef: 'passwordInput',
        retypePasswordInputRef: 'retypePasswordInput',
        emailInputRef: 'emailInput',
        socialLoginIframeRef: 'socialLoginIframe',
        socialLoginIframeHorizontalRef: 'socialLoginIframeHorizontal',
        joinCommunityByDefaultCheckboxRef: 'joinCommunityByDefaultCheckbox',

        isMobile() {
            return this.state.$view === 'mobile';
        },

        validateBeforeSubmit() {
            this.refs[this.emailInputRef].validate();
            this.refs[this.passwordInputRef].validate();

            if (!this.refs[this.emailInputRef].isValid() || !this.refs[this.passwordInputRef].isValid()) {
                return false;
            }

            return true;
        },

        getDataToSubmit() {
            const defaultData = {
                email: this.refs[this.emailInputRef].getValue(),
                password: this.refs[this.passwordInputRef].getValue()
            };
            return _.merge(defaultData, {
                privacyStatus: this._isJoinCommunity() ? this.getJoinCommunityStatus() : this.PRIVACY_STATUS.PRIVATE
            });
        },

        getSkinProperties() {//eslint-disable-line complexity
            if (this.shouldDialogBeClosed()) {
                return this.getCloseDialogSkinProperties();
            }
            const isSocialLogin = this.isSocialLogin();
            const lang = this.props.language;
            const noteText = this.getText(lang, 'SMRegister_Already_Have_User');
            const titleText = this.getText(lang, 'SMRegister_sign_up_new');
            const submitButtonText = this.getText(lang, 'SMRegister_sign_up_new');
            const switchDialogLinkText = this.getText(lang, 'dialogMixinTranslations_Log_In');
            const errMsgText = this.getErrorMessage();
            const skin = {
                'preloader': this.getDialogPreloaderSkinProperties(),
                'blockingLayer': {},
                'dialog': this.isMobile() ? {style: {marginTop: '-20px'}} : {},
                'note': {
                    children: noteText
                },
                'switchDialogLink': {
                    children: switchDialogLinkText,
                    onClick: this.props.onSwitchDialogLinkClick,
                    onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton
                },
                'title': {
                    children: titleText
                },
                'socialSectionDividerText': {
                    children: this.getText(lang, 'dialogMixinTranslations_or_sign_up_with')
                },
                'emailSectionDividerText': {
                    children: this.getText(lang, 'dialogMixinTranslations_or_email_new')
                },
                'errMsg': {
                    children: errMsgText
                },
                'content': this.createContent(),
                'okButton': {
                    children: submitButtonText,
                    onClick: this.submit,
                    onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton
                },
                'email': {
                    children: this._createEmailField({
                        enableLowerLabel: true,
                        placeholder: this.getText(lang, 'SMForm_Email')
                    })
                },
                'password': {
                    children: this._createPasswordField({
                        enableLowerLabel: true,
                        placeholder: this.getText(lang, 'dialogMixinTranslations_Choose_Password')
                    })
                },
                'retypePassword': {
                    children: this._createRetypePasswordField({
                        enableLowerLabel: true,
                        placeholder: this.getText(lang, 'SMForm_Retype_Password')
                    })
                },
                'contentWrapper': {
                    className: `${this.props.styleId}_${isSocialLogin ? 'horizontal' : 'vertical'} ${this.props.styleId}_${this.getMobileSocialLoginClass()}`
                },
                'checkboxWrapper': {
                    children: null
                },
                '': {
                    'aria-labelledby': `${this.props.id}title`,
                    onKeyDown: this.preventTabbingOut,
                    role: 'dialog',
                    style: {pointerEvents: 'auto'},
                    tabIndex: -1
                }
            };

            _.assign(skin, isSocialLogin ? this.getSocialLoginSkinParts('signup', lang, this._getSocialTranslationKeys(lang)) : {});


            _.assign(skin, this.shouldDisplayTermsFooter() ? this._createTermsFooter(lang, this._getPoliciesTranslationKeys(lang)) : {});
            _.assign(skin, this._createJoinCommunityFooter(lang, this._getJoinCommunityTranslationKeys(lang)));

            if (!this.props.notClosable) {
                const closeableSkinProperties = {
                    'blockingLayer': {},
                    'xButton': this.createXButton()
                };
                _.merge(skin, closeableSkinProperties);
            }
            return skin;
        },

        _isJoinCommunity() {
            return !!this.props.hasSocialAppInstalled;
        },

        _getCommunityCheckboxWrapperStyle() {
            return this.state.$view === 'mobile' ? 'inline-block' : 'flex';
        },

        _onJoinCommunityClick(e) {
            if (e.target !== this.refs.joinCommunityByDefaultCheckbox && e.target !== this.refs.joinCommunityReadMoreLink) {
                e.stopPropagation();
                this.refs.joinCommunityByDefaultCheckbox.click();
                this.updateSocialIframePrivacyStatus();
            }
        },

        _onJoinCommunityReadMoreClick() {
            this.setState({showCommunityText: !this.state.showCommunityText});
        },

        _isJoinCommunityCheckedByDefault() {
            if (_.isBoolean(this.props.checkCommunityCheckbox)) {
                return this.props.checkCommunityCheckbox;
            }
            if (_.isUndefined(this.props.smSettings.joinCommunityCheckedByDefault)) {
                return this._isJoinCommunity();
            }
            return !!this.props.smSettings.joinCommunityCheckedByDefault;
        },

        _createJoinCommunityFooter(lang, translationKeys) { // eslint-disable-line complexity
            return {
                'communityCheckboxWrapper': {
                    onClick: this._onJoinCommunityClick,
                    style: {
                        display: this._isJoinCommunity() ? this._getCommunityCheckboxWrapperStyle() : 'none',
                        tabIndex: this._isJoinCommunity() ? '0' : '-1'
                    },
                    onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton
                },
                'joinCommunityByDefaultCheckboxLabel': {
                    style: {display: this._isJoinCommunity() ? '' : 'none'},
                    children: this.getText(lang, translationKeys.joinCommunityByDefaultCheckboxLabel)
                },
                'joinCommunityReadMoreLink': {
                    children: this.state.showCommunityText ?
                        this.getText(lang, translationKeys.joinCommunityReadLessLink) :
                        this.getText(lang, translationKeys.joinCommunityReadMoreLink),
                    style: {
                        display: this._isJoinCommunity() ? '' : 'none',
                        tavIndex: this._isJoinCommunity() ? '0' : '-1'
                    },
                    onClick: this._onJoinCommunityReadMoreClick
                },
                'joinCommunityByDefaultCheckbox': {
                    'aria-labelledby': `${this.props.id}rememberMeCheckboxLabel`,
                    style: {display: this._isJoinCommunity() ? '' : 'none'},
                    defaultChecked: this._isJoinCommunityCheckedByDefault()
                },
                'codeOfConductLink': this._createPolicy('codeOfConduct', this.getText(lang, translationKeys.codeOfConduct)),
                'joinCommunityReadMoreContent': {
                    children: this.getText(lang, translationKeys.joinCommunityReadMoreContent),
                    style: {display: this.state.showCommunityText ? '' : 'none'}
                },
                'joinCommunityReadMoreParagraph': {
                    style: {display: this.state.showCommunityText ? '' : 'none'}
                }
            };
        },

        _isPolicyEnabled(policy) {
            return !!this.props.smSettings[policy] && this.props.smSettings[policy].enabled;
        },

        _createPolicy(key, children) {
            return {
                children: this._isPolicyEnabled(key) ? children : null,
                tabIndex: this._isPolicyEnabled(key) ? '0' : '-1',
                onClick: this.handlePolicyLink.bind(this, key),
                onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton
            };
        },

        _createTermsFooter(lang, translationKeys) {
            return {
                'signupAgreement': {
                    children: this.getText(lang, translationKeys.agreementContent)
                },
                'termsOfUseLink': this._createPolicy('termsOfUse', this.getText(lang, translationKeys.termsOfUse)),
                'andElement': {
                    children: this._isPolicyEnabled('termsOfUse') && this._isPolicyEnabled('privacyPolicy') ?
                        this.getText(lang, translationKeys.and) :
                        null
                },
                'privacyPolicyLink': this._createPolicy('privacyPolicy', this.getText(lang, translationKeys.privacyPolicy))
            };
        },

        _createEmailField(extraProps) {
            const emailProperties = {
                refId: this.emailInputRef,
                inputTitleKey: 'SMForm_Email',
                language: this.props.language
            };
            return this.createEmailInput(emailProperties, extraProps);
        },

        _createPasswordField(extraProps) {
            const passwordProperties = {
                refId: this.passwordInputRef,
                inputTitleKey: 'SMForm_Password',
                language: this.props.language
            };

            return this.createPasswordInput(passwordProperties, extraProps);
        },

        _createRetypePasswordField(extraProps) {
            const retypePasswordProperties = {
                refId: this.retypePasswordInputRef,
                inputTitleKey: 'SMForm_Retype_Password',
                language: this.props.language
            };
            return this.createPasswordInput(retypePasswordProperties, extraProps);
        },

        _getSocialTranslationKeys() {
            return {
                google: 'dialogMixinTranslations_signup_google',
                facebook: 'dialogMixinTranslations_signup_facebook',
                switchEmail: 'dialogMixinTranslations_signup_switch_email'
            };
        },

        _getJoinCommunityTranslationKeys() {
            return {
                joinCommunityByDefaultCheckboxLabel: 'SMForm_Policies_Join_Community_Label_new',
                joinCommunityReadMoreLink: 'SMForm_Policies_Join_Community_Link_new',
                joinCommunityReadLessLink: 'SMForm_Policies_Join_Community_Link_Less_new',
                joinCommunityReadMoreContent: 'SMForm_Policies_Join_Community_Read_More_Content_new',
                codeOfConduct: 'SMForm_Policies_CodeOfConduct_new'
            };
        },

        _getPoliciesTranslationKeys() {
            return {
                agreementContent: 'SMForm_Policies_Agreement_new',
                termsOfUse: 'SMForm_Policies_TermsOfUse_new',
                privacyPolicy: 'SMForm_Policies_PrivacyPolicy_new',
                codeOfConduct: 'SMForm_Policies_CodeOfConduct_new',
                and: 'SMForm_Policies_And_new'
            };
        },

        componentDidMount() {
            this.setPostMessageHandler('wix-social-login', this.onSocialLoginIframeMessage);
        },

        getFocusElement() {
            return this.state.socialLoginEnabled ?
                this.refs.switchDialogLink :
                this.refs[this.emailInputRef].refs.input;
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.dialogs.siteMemberDialogs.SignUpDialog', signUpDialog);

    return signUpDialog;
});

define([
    'santa-components',
    'lodash',
    'componentsCore',
    'dialogs/dialogMixin/dialogMixin',
    'dialogs/utils/dialogCustomCss',
    'prop-types'
], function (
    santaComponents,
    _,
    componentsCore,
    dialogMixin,
    getCustomCompCss,
    PropTypes
) {
    'use strict';

    /**
     * @class components.dialogs.NotificationDialog
     * @extends {core.skinBasedComp}
     * @extends {ReactCompositeComponent}
     * @property {comp.properties} props
     */
    const notificationDialog = {
        displayName: 'NotificationDialog',
        mixins: [componentsCore.mixins.customSkinBasedComp({customCssFunc: getCustomCompCss}), dialogMixin],

        propTypes: {
            title: PropTypes.string,
            description: PropTypes.string,
            buttonText: PropTypes.string,
            language: PropTypes.string,
            styleId: santaComponents.santaTypesDefinitions.Component.styleId,
            onButtonClick: PropTypes.func,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen
        },

        getSkinProperties() {
            if (this.shouldDialogBeClosed()) {
                return this.getCloseDialogSkinProperties();
            }
            const title = _.isUndefined(this.props.title) ? '=title missing=' : this.props.title;
            const description = _.isUndefined(this.props.description) ? '=description missing=' : this.props.description;
            const getButtonText = function (key) {
                return this.props.buttonText || this.getText(this.props.language, key);
            }.bind(this);
            return {
                'blockingLayer': {},
                'dialogTitle': {
                    children: title
                },
                'dialogDescription': {
                    children: description,
                    className: this.props.description ? '' : `${this.props.styleId}_noDialogDescription`
                },
                'okButton': {
                    children: getButtonText('SMRegister_GO'),
                    onClick: this.props.onButtonClick || this.closeDialog
                },
                'xButton': this.createXButton()
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.dialogs.NotificationDialog', notificationDialog);

    return notificationDialog;
});

define(['lodash', 'componentsCore', 'dialogs/dialogMixin/dialogMixin', 'prop-types', 'dialogs/utils/dialogCustomCss'],
    function (_, componentsCore, dialogMixin, PropTypes, getCustomCompCss) {
        'use strict';

        /**
         * @class components.siteMembersDialogs.WelcomeDialog
         * @extends {core.skinBasedComp}
         * @extends {ReactCompositeComponent}
         * @property {comp.properties} props
         */
        const welcomeDialog = {
            displayName: 'WelcomeDialog',
            mixins: [componentsCore.mixins.customSkinBasedComp({customCssFunc: getCustomCompCss}), dialogMixin],

            propTypes: {
                language: PropTypes.string
            },

            getDataToSubmit() {
                return {};
            },

            getSkinProperties() {
                if (this.shouldDialogBeClosed()) {
                    return this.getCloseDialogSkinProperties();
                }

                const lang = this.props.language;

                const titleTxt = this.getText(lang, 'SMWelcome_Title');
                const someInfoTxt = `${this.getText(lang, 'SMWelcome_SomeInfo').replace('&#39;', "'")} `;
                const checkTxt = this.getText(lang, 'SMWelcome_Check');
                const btnTxt = this.getText(lang, 'SMWelcome_Edit');
                const backToSiteLinkTxt = this.getText(lang, 'SMWelcome_Back');

                return {
                    'blockingLayer': {},
                    'title': {
                        children: titleTxt
                    },
                    'someInfoTxt': {
                        children: someInfoTxt
                    },
                    'checkTxt': {
                        children: checkTxt
                    },
                    'backToSiteLink': {
                        children: backToSiteLinkTxt,
                        onClick: this.onClickCloseButton
                    },
                    'okButton': {
                        children: this.createSubmitButton('okButton', btnTxt),
                        onClick: this.submit
                    },
                    'xButton': this.createXButton()
                };
            }
        };

        componentsCore.compRegistrar.register('wysiwyg.viewer.components.dialogs.siteMemberDialogs.WelcomeDialog',
            welcomeDialog);

        return welcomeDialog;
    });
